<template>
  <component :is="template"></component>
</template>
<script>

import { mapGetters } from "vuex";
import bancoList from "./bancoList";

export default {
  components: {
    bancoList
  },
  data() {
    return {
      banco: {
        estado : true,
      },
    };
  },
  mounted() {
    let muta = {};

    if (this.$route.query.oper === undefined) {
      muta.oper = "list";
      muta.banco = this.banco;
    } else {
      muta.oper = this.$route.query.oper;
    }
    
    this.$store.commit("gsfianza/OPERACION_BANCO", muta);
  },

  computed: { ...mapGetters({ template: "gsfianza/getBancoTpl" }) },
};
</script>
<style>
</style>